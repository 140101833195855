import React, { useState } from "react";
import { Card, Button, Form, Alert } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import {
  setGameCodeValid,
  setCurrentUser,
  setLoading,
  setError,
} from "../../../store/slices/gameMafiaSlice";
import { MafiaService } from "../../../api/services/mafia";
import GameDashboard from "./components/GameDashboard";
import AdminDashboard from "./components/AdminDashboard";


const MafiaGame: React.FC = () => {
  const dispatch = useDispatch();
  const { isGameCodeValid, loading, error } = useSelector((state: RootState) => state.GameMafia);
  const [gameCode, setGameCode] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);

  const validateCode = async (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(setLoading(true));
    dispatch(setError(null));

    if (gameCode.length <= 6 || gameCode.length > 100) {
      dispatch(setError("At least 6 characters required"));
      dispatch(setLoading(false));
      return;
    }

    try {
      const codeResponse = await MafiaService.verifyCode(gameCode);
      if (codeResponse.success) {
        console.log("codeResponse", codeResponse);
        if (codeResponse.accessToken) {
          localStorage.setItem("accessToken", codeResponse.accessToken);
        }
        if (codeResponse.user?.isAdmin) {
          setIsAdmin(true);
          return;
        }
        const userDetails = {
          id: codeResponse.user.id,
          name: codeResponse.user.name,
        }
        //if  lastlogin is greater than a day, alert welcome back username
        if (codeResponse.user.lastLogin && new Date(codeResponse.user.lastLogin).getTime() > Date.now() - 24 * 60 * 60 * 1000) {
          alert(`Welcome back ${userDetails.name}!`);
        }
        dispatch(setCurrentUser(userDetails));
        dispatch(setGameCodeValid(true));
      }
    } catch (err) {
      dispatch(setError("Failed to join game. Please try again."));
      console.error("Error:", err);
    } finally {
      dispatch(setLoading(false));
    }
  };
  if (isAdmin) {
    return <AdminDashboard />;
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#f8f9fa",
      }}
    >
      {isGameCodeValid ? <GameDashboard /> : <Card
        style={{
          width: "18rem",
          textAlign: "center",
          padding: "25px",
        }}
      >
        <Card.Title>ENTER GAME CODE</Card.Title>
        <Card.Body>
          <Form onSubmit={validateCode}>
            <Form.Group className="mb-3" controlId="gameCodeInput">
              <Form.Control
                type="password"
                placeholder="Enter game code"
                value={gameCode}
                onChange={(e) => setGameCode(e.target.value)}
                style={{ textAlign: "center" }}
                disabled={loading}
              />
            </Form.Group>
            {error && <Alert variant="danger">{error}</Alert>}
            <Button variant="primary" type="submit" disabled={loading}>
              {loading ? "Joining..." : "Submit"}
            </Button>
          </Form>
        </Card.Body>
        </Card>
      }
    </div>
  );
};

export default MafiaGame;