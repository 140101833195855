import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CurrentUser, GameState, ROLES } from '../../components/Fun/types/mafiaTypes';

type MafiaState = {
  currentUser: CurrentUser | null;
  gameState: GameState | null;
  role: ROLES | null;
  isGameCodeValid: boolean;
  loading: boolean;
  error: string | null;
}
const initialState: MafiaState = {
  currentUser: null,
  gameState: null,
  role: null,
  isGameCodeValid: false,
  loading: false,
  error: null,
};

const GameMafiaSlice = createSlice({
  name: 'mafia',
  initialState,
  reducers: {
    setGameCodeValid: (state, action: PayloadAction<boolean>) => {
      state.isGameCodeValid = action.payload;
    },
    setCurrentUser: (state, action: PayloadAction<CurrentUser>) => {
      state.currentUser = action.payload;
    },
    setGameState: (state, action: PayloadAction<GameState | null>) => {
      state.gameState = action.payload;
    },
    setRole: (state, action: PayloadAction<ROLES>) => {
      state.role = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
  },
});

export const { 
  setGameCodeValid, 
  setCurrentUser, 
  setGameState,
  setRole,
  setLoading, 
  setError 
} = GameMafiaSlice.actions;
export default GameMafiaSlice.reducer;