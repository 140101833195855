import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import PlayerCard from "./PlayerCard";
import { MafiaService } from "../../../../api/services/mafia";
import { setGameState } from "../../../../store/slices/gameMafiaSlice";
import { GAME_STATUS } from "../../types/mafiaTypes";

const PlayersDeck: React.FC = () => {
  const dispatch = useDispatch();
  const { currentUser, gameState } = useSelector((state: RootState) => state.GameMafia);

  const handleVote = async (playerId: number) => {
    try {
      const voteResponse = await MafiaService.vote(playerId);
    } catch (error) {
      console.error("Error voting:", error);
    }
  };

  const getVotedPlayers = (playerId: number) => {
    if (!gameState) return [];
    
    return Object.entries(gameState.votes)
      .filter(([_, votedTo]) => votedTo === playerId)
      .map(([voterId]) => {
        const voter = gameState.players.find(p => p.id === Number(voterId));
        return {
          userId: Number(voterId),
          userName: voter?.name || 'Unknown'
        };
      });
  };

  const isVotedByMe = (playerId: number): boolean => {
    if (!gameState || !currentUser) return false;
    console.log("checking vote from , to : ",gameState.votes[currentUser.id], playerId);
    return gameState.votes[currentUser.id] === playerId;
  };

  if (!gameState || !currentUser) {
    return <div>Loading players...</div>;
  }

  return (
    <div
      className="players-deck"
      style={{
        padding: "20px",
        height: "calc(100vh - 80px)",
        overflowY: "auto",
      }}
    >
      <div
        className="players-grid"
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
          gap: "20px",
          justifyItems: "center",
        }}
      >
        {gameState.players.map((player) => (
          <PlayerCard 
            key={player.id}
            playerId={player.id}
            playerName={player.name}
            votedPlayers={getVotedPlayers(player.id)}
            onVoteClick={handleVote}
            isVotedByMe={isVotedByMe(player.id)}
            disabled={gameState.status !== GAME_STATUS.ACTIVE}
          />
        ))}
      </div>
    </div>
  );
};

export default PlayersDeck; 