import { useRef } from 'react';
import './FunGame.styles.css';
import Santa from '../../../assets/img/santa.png';
import { CIRCLE_RADIUS } from './constants';
import { Balloon } from './Balloon';
import { useGameLogic } from './useGameLogic';
import { useSantaAnimation } from './useSantaAnimation';

const BalloonGame = () => {
  const gameAreaRef = useRef<HTMLDivElement>(null);
  const {
    balloons,
    score,
    circleColor,
    mousePosition,
    gameOver,
    isPaused,
    currentSpeed,
    handleRestart,
    clearAllBalloons,
    cycleGameMode,
    gameMode
  } = useGameLogic();

  const {
    santaPosition,
    sparkles,
    // eslint-disable-next-line
    isAnimating
  } = useSantaAnimation(score);

  return (
    <div
      ref={gameAreaRef}
      style={{
        position: 'relative',
        width: '100vw',
        height: '100vh',
        overflow: 'hidden',
        background: 'linear-gradient(to bottom, #a1c4fd, #c2e9fb)',
      }}
    >
      {/* Score Display */}
      <div style={{ position: 'fixed', top: 20, left: 20, fontSize: '24px', color: '#fff' }}>
        Score: {score}
      </div>
      {/* Speed Display */}
      <div style={{ position: 'fixed', top: 20, right: 20, fontSize: '12px', color: '#fff' }}>
        Speed: {currentSpeed.toFixed(2)}
      </div>
      <div style={{ position: 'fixed', top: 20, right: 120, fontSize: '12px' }}>
        <button
          onClick={clearAllBalloons}
          style={{
            backgroundColor: 'rgb(31, 76, 128)',
            color: '#fff',
            padding: '5px 10px',
            borderRadius: '15px',
            cursor: 'pointer',
            border: 'none'
          }}
        >
          Clear All
        </button>
      </div>
      <div style={{ position: 'fixed', top: 20, right: 220, fontSize: '12px' }}>
        <button
          onClick={cycleGameMode}
          style={{
            backgroundColor: 
              gameMode === 'bombs' ? 'rgb(200, 50, 50)' : 
              gameMode === 'frenzy' ? 'rgb(148, 0, 211)' : 
              'rgb(31, 76, 128)',
            color: '#fff',
            padding: '5px 10px',
            borderRadius: '15px',
            cursor: 'pointer',
            border: 'none'
          }}
        >
          {gameMode === 'bombs' ? 'BOMBS ONLY!' : 
           gameMode === 'frenzy' ? 'FRENZY!' : 
           'Normal Mode'}
        </button>
      </div>

      {/* Sparkles */}
      {sparkles.map(sparkle => (
        <div
          key={sparkle.id}
          style={{
            position: 'fixed',
            top: sparkle.y,
            left: sparkle.x,
            width: sparkle.size,
            height: sparkle.size,
            backgroundColor: sparkle.color,
            borderRadius: '50%',
            opacity: sparkle.opacity,
            filter: 'blur(0.5px)',
            animation: 'twinkle 0.5s ease-in-out infinite alternate',
          }}
        />
      ))}
      
      {santaPosition < window.innerWidth && (
        <img 
          src={Santa} 
          alt="Santa" 
          style={{ 
            position: 'fixed', 
            top: 10, 
            left: santaPosition, 
            width: '30%', 
            height: '30%',
            transition: 'left 0.05s linear',
            zIndex: 1,
          }} 
        />
      )}

      {/* Pause Message */}
      {isPaused && !gameOver && (
        <div style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          fontSize: '24px',
          color: '#fff',
          zIndex: 10,
          textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
          borderRadius: '10px',
          backgroundColor: 'rgb(25, 25, 106)',
          padding: '5%',
        }}>
          <span style={{ fontSize: '48px' }}>Paused</span>
          <br />
          Move Mouse Back to Window
        </div>
      )}

      {/* Game Over Message */}
      {gameOver && (
        <div style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          fontSize: '48px',
          color: '#fff',
          zIndex: 10,
          textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
          borderRadius: '10px',
          backgroundColor: 'rgb(25, 25, 106)',
          padding: '5%',
        }}>
          Game Over!
          <br />
          Score: {score}
          <span style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', fontSize: '24px' }}>
            <button 
              onClick={handleRestart} 
              style={{ backgroundColor: 'rgb(31, 76, 128)', color: '#fff', padding: '10px 20px', borderRadius: '30px', cursor: 'pointer' }}
            >
              Play Again
            </button>
          </span>
        </div>
      )}

      {/* Balloons */}
      {balloons.map(balloon => (
        <Balloon key={balloon.id} balloon={balloon} />
      ))}

      {/* Mouse Circle */}
      {!gameOver && (
        <div
          style={{
            position: 'fixed',
            left: mousePosition.x - CIRCLE_RADIUS,
            top: mousePosition.y - CIRCLE_RADIUS,
            width: `${CIRCLE_RADIUS * 2}px`,
            height: `${CIRCLE_RADIUS * 2}px`,
            border: `3px solid ${circleColor}`,
            borderRadius: '50%',
            pointerEvents: 'none',
            transition: 'border-color 0.3s',
          }}
        />
      )}
    </div>
  );
};

export default BalloonGame;
