import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { MafiaService } from '../../../../api/services/mafia';
import { toast } from 'react-toastify';
interface HostConfigProps {
  show: boolean;
  onHide: () => void;
}

const HostConfig: React.FC<HostConfigProps> = ({ show, onHide }) => {
  const [killerCount, setKillerCount] = useState(1);
  const [doctorCount, setDoctorCount] = useState(1);

  const handleSubmit = async () => {
    try {
      const response = await MafiaService.changeConfig(killerCount, doctorCount);
      if (response.success) {
        toast.success('Game configuration updated successfully');
        onHide();
      }
    } catch (error) {
      console.error('Failed to update game config:', error);
    }
  };

  const handleGenerateRoles = async () => {
    try {
      // First save the configuration
      const configResponse = await MafiaService.changeConfig(killerCount, doctorCount);
      if (configResponse.success) {
        // Then generate roles
        const response = await MafiaService.generateRoles();
        if (response.success) {
          toast.success('Roles generated successfully');
        }
      }
    } catch (error) {
      console.error('Failed to generate roles:', error);
      toast.error('Failed to generate roles');
    }
  };

  const handleResetVotes = async () => {
    try {
      const response = await MafiaService.resetVotes();
      if (response.success) {
        toast.success('Votes reset successfully');
      }
    } catch (error) {
      console.error('Failed to reset votes:', error);
      toast.error('Failed to reset votes');
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Game Configuration</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form style={{color: 'black'}}>
          <Form.Group className="mb-3" >
            <Form.Label>Number of Killers</Form.Label>
            <Form.Control
              type="number"
              min={1}
              value={killerCount}
              onChange={(e) => setKillerCount(parseInt(e.target.value))}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Number of Doctors</Form.Label>
            <Form.Control
              type="number"
              min={1}
              value={doctorCount}
              onChange={(e) => setDoctorCount(parseInt(e.target.value))}
            />
          </Form.Group>
        </Form>
        <div className="d-grid gap-2">
          <Button variant="warning" onClick={handleGenerateRoles}>
            Generate Roles
          </Button>
          <Button variant="danger" onClick={handleResetVotes}>
            Reset Votes
          </Button>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default HostConfig;
