import React from "react";
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";

interface PlayerCardProps {
  playerName: string;
  playerId: number;
  votedPlayers: {
    userId: number;
    userName: string;}[];
  onVoteClick: (playerId: number) => void;
  isVotedByMe: boolean;
  disabled?: boolean;
}

export default function PlayerCard({ 
  playerName, 
  playerId, 
  votedPlayers, 
  onVoteClick, 
  isVotedByMe,
  disabled = false 
}: PlayerCardProps) {
  const avatarUrl = `https://api.dicebear.com/9.x/adventurer/svg?seed=${playerName}`;

  return (
    <Card 
      style={{ 
        width: '200px',
        cursor: disabled ? 'not-allowed' : 'pointer',
        border: isVotedByMe ? '2px solid #007bff' : '1px solid rgba(0,0,0,.125)',
        opacity: disabled ? 0.7 : 1
      }}
      onClick={() => !disabled && onVoteClick(playerId)}
    >
      <Card.Body>
        <Card.Title>{playerName}</Card.Title>
        <img
          src={avatarUrl}
          alt="avatar"
          style={{ width: '100%', height: 'auto' }}
        />
      </Card.Body>
      <Card.Footer style={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
        {votedPlayers.map((voter) => (
          <OverlayTrigger
            key={voter.userId}
            placement="top"
            overlay={<Tooltip>{voter.userName}</Tooltip>}
          >
            <img
              src={`https://api.dicebear.com/9.x/adventurer/svg?seed=${voter.userName}`}
              alt={`${voter.userName}'s avatar`}
              style={{ width: '30px', height: '30px', borderRadius: '50%' }}
            />
          </OverlayTrigger>
        ))}
      </Card.Footer>
    </Card>
  );
} 