export const API_ENDPOINTS = {
  MAFIA_GAME: {
    VERIFY_CODE: '/auth/login',
    USER_INFO: '/user/info',
    // ALL_PLAYERS: '/game/mafia/players',
    UPDATE_NAME: '/auth/change-username',
    LOG_OUT: '/forget-me',
    VOTE: '/vote',
    GET_GAME_STATE: '/games/get-game-state',
    // GET_VOTES: '/all-votes',

    // Host only
    GENERATE_ROLES: '/game/mafia/generate-roles',
    KILL_PLAYER: '/game/mafia/add-dead-player',
    RESET_VOTES: '/game/mafia/clear-votes',
    CHANGE_CONFIG: '/game/mafia/change-config',
    START_GAME: '/games/start-mafia',

    // Admin only
    RESET_DB: '/reset-users',
    CREATE_GAME: '/games/create',
    END_GAME: '/games/reset',
  },
} as const; 