export enum GAME_NAMES {
    MAFIA = "mafia"
}

export enum GAME_STATUS {
    LOBBY = "lobby",
    ACTIVE = "active",
    ENDED = "ended"
}

export enum ROLES {
    KILLER = "KILLER",
    DOCTOR = "DOCTOR",
    CITIZEN = "CITIZEN"
}

export type GameState = {
    type: GAME_NAMES;
    status: GAME_STATUS;
    host: number;
    votes: Record<number, number>;
    players: Player[];
    killerCount: number;
    doctorCount: number;
};
export type CurrentUser = {
    id: number;
    name: string;
}
export type Player = {
    id: number;
    name: string;
    isAlive: boolean;
}
