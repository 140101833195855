import axios from 'axios';

const baseURL = process.env.REACT_APP_API_BASE_URL || 'https://portfolio-k8u2.onrender.com/api';

export const axiosInstance = axios.create({
  baseURL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});


// Add a request interceptor to attach the token
axiosInstance.interceptors.request.use(
  (config) => {
      const accessToken = localStorage.getItem('accessToken');
      if (accessToken) {
          config.headers.Authorization = `Bearer ${accessToken}`;
      }
      return config;
  },
  (error) => {
      return Promise.reject(error);
  }
);

// Request interceptor
// axiosInstance.interceptors.request.use(
//   (config) => {
//     // Add auth token if needed
//     const token = localStorage.getItem('token');
//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
//   },
//   (error) => Promise.reject(error)
// );

// // Response interceptor
// axiosInstance.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     // Handle global errors (401, 403, etc.)
//     return Promise.reject(error);
//   }
// ); 