import { io } from "socket.io-client";

// Replace with your server's WebSocket URL
const SERVER_URL = process.env.REACT_APP_SOCKET_URL || "https://portfolio-k8u2.onrender.com:10000";

const socket = io(SERVER_URL, {
  autoConnect: false, // Disable auto-connect to control when to connect
  secure: true,
  // transports: ['websocket']
});

export default socket;
