import { axiosInstance } from '../config/axios';
import { API_ENDPOINTS } from '../config/endpoints';

interface VotePayload {
  voteForUserId: number;
}

interface RoleAssignmentPayload {
  killerCount: number;
  doctorCount: number;
}

interface CreateGamePayload {
  killerCount: number;
  doctorCount: number;
  hostId: number;
}

interface NameUpdatePayload {
  name: string;
}

interface CodeVerificationPayload {
  accessCode: string;
}

export const MafiaService = {
  // Game Setup
  verifyCode: async (code: string) => {
    const response = await axiosInstance.post<ApiResponse>(
      API_ENDPOINTS.MAFIA_GAME.VERIFY_CODE, 
      { accessCode: code } as CodeVerificationPayload
    );
    return response.data;
  },

  // User Management
  // getUserInfo: async () => {
  //   const response = await axiosInstance.get<ApiResponse>(
  //     API_ENDPOINTS.MAFIA_GAME.USER_INFO
  //   );
  //   return response.data;
  // },

  updateName: async (oldName: string, name: string) => {
    const payload = {
      oldUsername: oldName,
      newUsername: name,
    };

    const response = await axiosInstance.post<ApiResponse>(
      API_ENDPOINTS.MAFIA_GAME.UPDATE_NAME,
      payload
    );
    return response.data;
  },

  logOut: async () => {
    const response = await axiosInstance.post<ApiResponse>(
      API_ENDPOINTS.MAFIA_GAME.LOG_OUT
    );
    return response.data;
  },


  vote: async (playerId: number) => {
    const response = await axiosInstance.post<ApiResponse>(
      API_ENDPOINTS.MAFIA_GAME.VOTE, 
      { voteForUserId: playerId } as VotePayload
    );
    return response.data;
  },

  getGameState: async () => {
    const response = await axiosInstance.get<ApiResponse>(
      API_ENDPOINTS.MAFIA_GAME.GET_GAME_STATE
    );
    return response.data;
  },

  // Admin Actions

  generateRoles: async () => {
    const response = await axiosInstance.post<ApiResponse>(
      API_ENDPOINTS.MAFIA_GAME.GENERATE_ROLES
    );
    return response.data;
  },

  changeConfig: async (killers: number, doctors: number) => {
    const payload: RoleAssignmentPayload = {
      killerCount: killers,
      doctorCount: doctors
    };
    const response = await axiosInstance.post<ApiResponse>(
      API_ENDPOINTS.MAFIA_GAME.CHANGE_CONFIG, 
      payload
    );
    return response.data;
  },

  startGame: async () => {
    const response = await axiosInstance.post<ApiResponse>(
      API_ENDPOINTS.MAFIA_GAME.START_GAME
    );
    return response.data;
  },

  killPlayer: async (playerId: number) => {
    const response = await axiosInstance.post<ApiResponse>(
      API_ENDPOINTS.MAFIA_GAME.KILL_PLAYER, 
      { playerId }
    );
    return response.data;
  },

  resetVotes: async () => {
    const response = await axiosInstance.delete<ApiResponse>(
      API_ENDPOINTS.MAFIA_GAME.RESET_VOTES
    );
    return response.data;
  },


  resetDB: async () => {
    const response = await axiosInstance.delete<ApiResponse>(
      API_ENDPOINTS.MAFIA_GAME.RESET_DB
    );
    return response.data;
  },
 
  createGame: async (killers: number, doctors: number, hostId: number) => {
    const payload: CreateGamePayload = {
      killerCount: killers,
      doctorCount: doctors,
      hostId: hostId
    };
    const response = await axiosInstance.post<ApiResponse>(
      API_ENDPOINTS.MAFIA_GAME.CREATE_GAME,
      payload
    );
    return response.data;
  },

  endGame: async () => {
    const response = await axiosInstance.post<ApiResponse>(
      API_ENDPOINTS.MAFIA_GAME.END_GAME
    );
    return response.data;
  },
};

// Types
interface ApiResponse {
  success: boolean;
  message?: string;
  accessToken?: string;
  user?: {
    id: number;
    name: string;
    lastLogin?: string;
    isAdmin?: boolean;
    authenticated?: boolean;
    vote?: number | null;
    role?: string | null;
  };
  players?: Array<{
    id: number;
    name: string;
  }>;
  votes?: Array<{
    userId: number;
    userName: string;
    votedToUserId: number;
    votedToUserName: string;
  }>;
}

export type { ApiResponse, VotePayload, RoleAssignmentPayload }; 