import { useState, useRef, useEffect } from 'react';
import { 
  COLORS, INITIAL_SPAWN_INTERVAL, MIN_SPAWN_INTERVAL,
  INITIAL_SPEED, MAX_SPEED, MIN_SPEED, DIFFICULTY_INCREASE_INTERVAL, 
  INITIAL_BOMB_CHANCE, MAX_BOMB_CHANCE, MULTI_COLOR_CHANCE, SPEED_CONTROL_CHANCE, 
  SPEED_CHANGE_FACTOR, BALLOON_SIZE, CIRCLE_RADIUS
} from './constants';
import {Balloon, Direction} from './types';

type GameMode = 'normal' | 'bombs' | 'frenzy';

export const useGameLogic = () => {
  const [balloons, setBalloons] = useState<Balloon[]>([]);
  const [score, setScore] = useState(0);
  const [circleColor, setCircleColor] = useState('blue');
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [spawnInterval, setSpawnInterval] = useState(INITIAL_SPAWN_INTERVAL);
  const [currentSpeed, setCurrentSpeed] = useState(INITIAL_SPEED);
  const [bombChance, setBombChance] = useState(INITIAL_BOMB_CHANCE);
  const [gameOver, setGameOver] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const balloonIdRef = useRef(0);
  const spawnTimeoutRef = useRef<NodeJS.Timeout>();
  const [gameMode, setGameMode] = useState<GameMode>('normal');
  const [survivalTimer, setSurvivalTimer] = useState(0);

  const getRandomDirection = (): Direction => {
    const directions: Direction[] = ['left', 'right', 'top', 'bottom'];
    return directions[Math.floor(Math.random() * directions.length)];
  };

  const getSpawnPosition = (direction: Direction) => {
    switch (direction) {
      case 'left':
        return { x: -BALLOON_SIZE, y: Math.random() * window.innerHeight };
      case 'right':
        return { x: window.innerWidth + BALLOON_SIZE, y: Math.random() * window.innerHeight };
      case 'top':
        return { x: Math.random() * window.innerWidth, y: -BALLOON_SIZE };
      case 'bottom':
        return { x: Math.random() * window.innerWidth, y: window.innerHeight + BALLOON_SIZE };
    }
  };

  const getSpeedControlSymbol = (direction: Direction, isSpeedUp: boolean) => {
    switch (direction) {
      case 'left':
        return !isSpeedUp ? '<<' : '>>';
      case 'right':
        return !isSpeedUp ? '>>' : '<<';
      case 'top':
        return !isSpeedUp ? '∧∧' : '∨∨';
      case 'bottom':
        return !isSpeedUp ? '∨∨' : '∧∧';
    }
  };

  const isOutOfBounds = (balloon: Balloon) => {
    const { x, y } = balloon;
    return (
      x < -BALLOON_SIZE ||
      x > window.innerWidth + BALLOON_SIZE ||
      y < -BALLOON_SIZE ||
      y > window.innerHeight + BALLOON_SIZE
    );
  };

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (!gameOver) {
        setMousePosition({ x: e.clientX, y: e.clientY });
      }
    };

    const handleMouseLeave = () => setIsPaused(true);
    const handleMouseEnter = () => setIsPaused(false);

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseout', handleMouseLeave);
    window.addEventListener('mouseover', handleMouseEnter);
    
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseout', handleMouseLeave);
      window.removeEventListener('mouseover', handleMouseEnter);
    };
  }, [gameOver]);

  useEffect(() => {
    const difficultyInterval = setInterval(() => {
      if (!gameOver && !isPaused) {
        setSpawnInterval(prev => Math.max(MIN_SPAWN_INTERVAL, prev * 0.9));
        setCurrentSpeed(prev => Math.min(MAX_SPEED, prev * 1.1));
        setBombChance(prev => Math.min(MAX_BOMB_CHANCE, prev * 1.1));
      }
    }, DIFFICULTY_INCREASE_INTERVAL);

    return () => clearInterval(difficultyInterval);
  }, [gameOver, isPaused]);

  useEffect(() => {
    const spawnBalloon = () => {
      if (!gameOver && !isPaused) {
        const direction = getRandomDirection();
        const { x, y } = getSpawnPosition(direction);
        const isSpeedControl = gameMode !== 'bombs' && Math.random() < SPEED_CONTROL_CHANCE;
        const isMultiColor = gameMode !== 'bombs' && !isSpeedControl && Math.random() < MULTI_COLOR_CHANCE;
        
        const isBomb = gameMode === 'bombs' || (gameMode !== 'frenzy' && !isSpeedControl && !isMultiColor && Math.random() < bombChance);
        
        const isSpeedUp = isSpeedControl && Math.random() < 0.5;
        const speedSymbol = isSpeedControl ? getSpeedControlSymbol(direction, isSpeedUp) : undefined;
        const color = isBomb ? 'black' : isMultiColor ? 'rainbow' : isSpeedControl ? (isSpeedUp ? '#90EE90' : '#FFB6C1') : COLORS[Math.floor(Math.random() * COLORS.length)];
        
        setBalloons(prev => [
          ...prev,
          {
            id: balloonIdRef.current++,
            color,
            x,
            y,
            speed: currentSpeed + Math.random() * 2,
            direction,
            isBomb,
            isMultiColor,
            isSpeedControl,
            speedSymbol
          },
        ]);
        
        const nextSpawnDelay = gameMode === 'frenzy' ? 60 : spawnInterval;
        spawnTimeoutRef.current = setTimeout(spawnBalloon, nextSpawnDelay);
      } else {
        spawnTimeoutRef.current = setTimeout(spawnBalloon, 100);
      }
    };

    spawnTimeoutRef.current = setTimeout(spawnBalloon, spawnInterval);

    return () => {
      if (spawnTimeoutRef.current) {
        clearTimeout(spawnTimeoutRef.current);
      }
    };
  }, [spawnInterval, currentSpeed, gameOver, bombChance, isPaused, gameMode]);

  useEffect(() => {
    const checkCollisions = () => {
      setBalloons(prev => {
        const collidedBalloons = prev.filter(balloon => {
          const balloonCenterX = balloon.x + BALLOON_SIZE / 2;
          const balloonCenterY = balloon.y + BALLOON_SIZE / 2;
          const distance = Math.sqrt(
            Math.pow(balloonCenterX - mousePosition.x, 2) + 
            Math.pow(balloonCenterY - mousePosition.y, 2)
          );
          return distance > (CIRCLE_RADIUS + BALLOON_SIZE / 2);
        });

        const removedBalloons = prev.filter(balloon => !collidedBalloons.includes(balloon));
        removedBalloons.forEach(balloon => {
          if (balloon.isBomb) {
            setGameOver(true);
          } else if (gameMode === 'frenzy') {
            if (balloon.isMultiColor) {
              setScore(s => s + 3);
            } else if (balloon.isSpeedControl) {
              setScore(s => s + 2);
              const isSpeedUp = balloon.speedSymbol === getSpeedControlSymbol(balloon.direction, true);
              setCurrentSpeed(speed => {
                const newSpeed = isSpeedUp ? speed * SPEED_CHANGE_FACTOR : speed / SPEED_CHANGE_FACTOR;
                return Math.min(Math.max(newSpeed, MIN_SPEED), MAX_SPEED);
              });
            } else {
              setScore(s => s + 1);
            }
          } else if (gameMode === 'normal') {
            if (balloon.isSpeedControl) {
              const isSpeedUp = balloon.speedSymbol === getSpeedControlSymbol(balloon.direction, true);
              setCurrentSpeed(speed => {
                const newSpeed = isSpeedUp ? speed * SPEED_CHANGE_FACTOR : speed / SPEED_CHANGE_FACTOR;
                return Math.min(Math.max(newSpeed, MIN_SPEED), MAX_SPEED);
              });
              setScore(s => s + 10);
            } else if (balloon.isMultiColor) {
              setScore(s => s + 25);
              setCircleColor(COLORS[Math.floor(Math.random() * COLORS.length)]);
            } else if (balloon.color === circleColor) {
              setScore(s => s + 5);
            } else {
              setScore(s => s - 10);
              setCircleColor(balloon.color);
            }
          }
        });

        return collidedBalloons;
      });
    };

    const updateBalloons = () => {
      setBalloons(prev =>
        prev
          .map(balloon => {
            let { x, y } = balloon;
            switch (balloon.direction) {
              case 'left':
                x += balloon.speed;
                break;
              case 'right':
                x -= balloon.speed;
                break;
              case 'top':
                y += balloon.speed;
                break;
              case 'bottom':
                y -= balloon.speed;
                break;
            }
            return { ...balloon, x, y };
          })
          .filter(balloon => !isOutOfBounds(balloon))
      );
    };

    if (!gameOver && !isPaused) {
      const animation = requestAnimationFrame(() => {
        updateBalloons();
        checkCollisions();
      });
      return () => cancelAnimationFrame(animation);
    }
  }, [balloons, mousePosition, circleColor, gameOver, isPaused]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (gameMode === 'bombs' && !gameOver && !isPaused) {
      timer = setInterval(() => {
        setScore(prev => prev + 1);
      }, 1000);
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [gameMode, gameOver, isPaused]);

  const handleRestart = () => {
    window.location.reload();
  };

  const clearAllBalloons = () => {
    setBalloons([]);
  };

  const cycleGameMode = () => {
    setBalloons([]);
    setScore(0);
    setSurvivalTimer(0);
    setGameMode(current => {
      switch (current) {
        case 'normal': return 'bombs';
        case 'bombs': return 'frenzy';
        case 'frenzy': return 'normal';
      }
    });
  };

  return {
    balloons,
    score,
    circleColor,
    mousePosition,
    gameOver,
    isPaused,
    currentSpeed,
    handleRestart,
    clearAllBalloons,
    cycleGameMode,
    gameMode
  };
};