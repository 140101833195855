import React, { useEffect, useState } from 'react';
import { GameState, ROLES, GAME_STATUS, Player } from '../../types/mafiaTypes';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { Container, Card, ListGroup, Button, Col, Row, Form } from 'react-bootstrap';
import PlayersDeck from './PlayersDeck';
import Navbar, { TopBar } from './TopBar';
import { MafiaService } from '../../../../api/services/mafia';
import { setGameState, setRole, setCurrentUser } from '../../../../store/slices/gameMafiaSlice';
import socket from "../../../../api/config/socket";
import { PencilFill } from 'react-bootstrap-icons';
import HostConfig from './HostConfig';

const GameDashboard: React.FC = () => {
  const dispatch = useDispatch();
  const { currentUser, gameState, loading } = useSelector((state: RootState) => state.GameMafia);
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState(currentUser?.name || 'Anonymous');

  const [isConfigOpen, setIsConfigOpen] = useState(false);

  const getAvatarUrl = (playerName: string, size: number) => {
    return `https://api.dicebear.com/9.x/adventurer/svg?seed=${playerName}&size=${size}`;
  }

  const handleHostConfig = () => {
    setIsConfigOpen(true);
  }

  const handleNameUpdate = async () => {
    if (currentUser?.name && editedName !== currentUser.name) {
      try {
        const response = await MafiaService.updateName(currentUser.name, editedName);
        if (response.success) {
          dispatch(setCurrentUser({ ...currentUser, name: editedName }));
        }
      } catch (error) {
        console.error('Failed to update name:', error);
        setEditedName(currentUser.name); // Reset to original name if update fails
      }
    }
    setIsEditing(false);
  };
  
  useEffect(() => {
    // Connect to WebSocket when the component mounts
    socket.connect();
    const token = localStorage.getItem('accessToken');
    // Authenticate with the server
    socket.emit("authenticate", { token: token });

    // Listen for role updates
    socket.on("roleUpdate", (data) => {
      console.log("Role update received:", data);
      dispatch(setRole(data.role));
    });

    // Listen for game state updates
    socket.on("gameStateUpdate", (data) => {
      console.log("Game state update received:", data);
      dispatch(setGameState(data));
    });

    setTimeout(() => {
      MafiaService.getGameState();
    }, 500);

    // Cleanup the socket when the component unmounts
    return () => {
      socket.disconnect();
    };
  }, []);

  const handleStartGame = async () => {
    const response = await MafiaService.startGame();
    if (response.success) {
      console.log("Game started");
    }
  };

    if(gameState?.status === GAME_STATUS.ACTIVE) {
        return (
            <>
                <TopBar />
                <Container fluid className="mt-3">
                    <PlayersDeck />
                </Container>
            </>
        );
    }
  return (
    <>
        <Card className="mt-3" style={{width: '25%'}}>
            <Card.Header>
                <Row style={{padding: '15px'}}>
                    <Col xs={6}>
                        <Row>
                            <Col>
                                {isEditing ? (
                                    <Form.Control
                                        type="text"
                                        value={editedName}
                                        onChange={(e) => setEditedName(e.target.value)}
                                        onBlur={handleNameUpdate}
                                        autoFocus
                                        style={{
                                            width: '80%',
                                            display: 'inline-block',
                                            marginLeft: '10px'
                                        }}
                                    />
                                ) : (
                                    <>
                                        <span style={{fontWeight: 'bold', fontSize: '1.2rem', marginLeft: '10px'}}>{editedName}</span>
                                        <PencilFill 
                                            style={{marginLeft: '10px', cursor: 'pointer'}} 
                                            onClick={() => setIsEditing(true)}
                                        />
                                    </>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <img src={getAvatarUrl(currentUser?.name, 40)} alt="avatar" />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={6} className="d-flex align-items-center justify-content-center flex-column">
                        {currentUser?.id === gameState?.host && (
                        <>
                            <Button
                                onClick={handleStartGame}
                                variant="primary"
                                style={{
                                    borderRadius: '10px',
                                    marginBottom: '10px'
                                }}
                            >
                                Start Game
                            </Button>
                            <Button
                                onClick={handleHostConfig}
                                variant="secondary"
                                style={{
                                    borderRadius: '10px'
                                }}
                            >
                                Config
                            </Button>
                        </>
                        )}
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <Card.Title>In Lobby</Card.Title>
                <ListGroup variant="flush">
                    {gameState?.players.map((player: Player) => (
                        <ListGroup.Item key={player.id}>
                            <img src={getAvatarUrl(player.name, 25)} alt={"avatar"+player.name} style={{width: '25%'}}/>
                            {player.name} {player.isAlive ? '' : '(Eliminated)'}
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            </Card.Body>
        </Card>
        <HostConfig show={isConfigOpen} onHide={() => setIsConfigOpen(false)} />
    </>
  );
};

export default GameDashboard;
