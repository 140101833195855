import React, { useState } from 'react';
import { Card, Button, Container, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { MafiaService } from '../../../../api/services/mafia';
import { setGameState } from '../../../../store/slices/gameMafiaSlice';
import { GAME_STATUS, GAME_NAMES } from '../../types/mafiaTypes';
import { toast } from 'react-toastify';
import PlayerCard from './PlayerCard';

const AdminDashboard: React.FC = () => {
  const dispatch = useDispatch();
  const { currentUser, gameState } = useSelector((state: RootState) => state.GameMafia);
  const [killers, setKillers] = useState(0);
  const [doctors, setDoctors] = useState(0);
  const [hostId, setHostId] = useState(currentUser?.id || null);
  
  const handleCreateGame = async () => {
    try {
      // Assuming you'll add this endpoint to create a new game
      const response = await MafiaService.createGame(killers, doctors, hostId);
      if (response.success) {
        toast.success("Game created successfully");
      } else {
        toast.error("Failed to create game");
      }
    } catch (error) {
      console.error('Error creating game:', error);
    }
  };

  const handleEndGame = async () => {
    try {
      // Assuming you'll add this endpoint to end the game
      const response = await MafiaService.endGame();
      if (response.success) {
        toast.success("Game ended successfully");
      } else {
        toast.error("Failed to end game");
      }
    } catch (error) {
      console.error('Error ending game:', error);
    }
  };

  const handleSelectHost = async (playerId: number) => {
    setHostId(playerId);
  };

  return (
    <Container fluid>
      <Card className="mt-3 mb-4">
        <Card.Body className="d-flex justify-content-between align-items-center">
          <Card.Title className="mb-0">Game Controls</Card.Title>
          <div>
            <Form.Group className="mb-3">
                <Form.Label>Number of Killers</Form.Label>
                <Form.Control
                type="number"
                value={killers}
                onChange={(e) => setKillers(Number(e.target.value))}
                min="0"
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Number of Doctors</Form.Label>
                <Form.Control
                type="number"
                value={doctors}
                onChange={(e) => setDoctors(Number(e.target.value))}
                min="0"
                />
            </Form.Group>
          </div>
          <div>
            <Button 
              variant="success" 
              onClick={handleCreateGame} 
              className="me-2"
            >
              Create New Game
            </Button>
            <Button 
              variant="danger" 
              onClick={handleEndGame}
            >
              End Game
            </Button>
          </div>
        </Card.Body>
      </Card>

      <Card>
        <Card.Header>
          <h5 className="mb-0">Select Game Host</h5>
        </Card.Header>
        <Card.Body>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
              gap: "20px",
              justifyItems: "center",
            }}
          >
            {gameState?.players.map((player) => (
              <PlayerCard 
                key={player.id}
                playerId={player.id}
                playerName={player.name}
                votedPlayers={[]}
                onVoteClick={handleSelectHost}
                isVotedByMe={gameState.host === player.id}
                disabled={gameState.status === GAME_STATUS.ACTIVE}
              />
            ))}
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default AdminDashboard;
